

// Import bootstrap variables and mixins for reference
@import (reference) "../less-bootstrap/variables.less";
@import (reference) "../less-bootstrap/mixins.less";

// Initialize Theme Variables
@import (reference) "../less-hostplan/variables.less";


*::-moz-selection {
  background: @theme-color none repeat scroll 0 0; /* Firefox */
}
/* text-theme-color */
.text-theme-colored {
  color: @theme-color !important;
}
/* text-theme-color 2 */
.text-theme-colored-2 {
  color: @theme-color-2 !important;
}
/* text-hover-theme-color */
.text-hover-theme-colored {
  &:hover {
    color: @theme-color !important;
  }
}
/* text-hover-theme-color 2*/
.text-hover-theme-colored-2 {
  &:hover {
    color: @theme-color-2 !important;
  }
}

/* background-theme-color */
.bg-theme-colored {
  background-color: @theme-color !important;
}
/* background-theme-color-2 */
.bg-theme-colored-2 {
  background-color: @theme-color-2 !important;
}
.bg-theme-colored-lighter2 {
  background-color: lighten(@theme-color, 3%) !important;
}
.bg-theme-colored-lighter3 {
  background-color: lighten(@theme-color, 5%) !important;
}
.bg-theme-colored-lighter4 {
  background-color: lighten(@theme-color, 7%) !important;
}
.bg-theme-colored-darker2 {
  background-color: darken(@theme-color, 3%) !important;
}
.bg-theme-colored-darker3 {
  background-color: darken(@theme-color, 5%) !important;
}
.bg-theme-colored-darker4 {
  background-color: darken(@theme-color, 7%) !important;
}

/* background-color-transparent */
.bg-theme-colored-transparent-9 {
  background-color: fade(@theme-color, 90%);
}
.bg-theme-colored-transparent-8 {
  background-color: fade(@theme-color, 80%);
}
.bg-theme-colored-transparent-7 {
  background-color: fade(@theme-color, 70%);
}
.bg-theme-colored-transparent-6 {
  background-color: fade(@theme-color, 60%);
}
.bg-theme-colored-transparent-5 {
  background-color: fade(@theme-color, 50%);
}
.bg-theme-colored-transparent-4 {
  background-color: fade(@theme-color, 40%);
}
.bg-theme-colored-transparent-3 {
  background-color: fade(@theme-color, 30%);
}
.bg-theme-colored-transparent-2 {
  background-color: fade(@theme-color, 20%);
}
.bg-theme-colored-transparent-1 {
  background-color: fade(@theme-color, 10%);
}
.bg-theme-colored-transparent {
  background-color: fade(@theme-color, 75%) !important;
}

/* bg-hover-theme-color */
.bg-hover-theme-colored {
  &:hover {
    background: @theme-color !important;
    border-color: @theme-color !important;
    color: @white-base !important;
    
    h1,h2,h3,h4,h5,h6,p,a,i {
      color: @white-base !important;
    }
  }
}

/* Layer-overlay */
.overlay-theme-colored-1:before {
  background-color: fade(@theme-color, 10%) !important;
}
.overlay-theme-colored-2:before {
  background-color: fade(@theme-color, 20%) !important;
}
.overlay-theme-colored-3:before {
  background-color: fade(@theme-color, 30%) !important;
}
.overlay-theme-colored-4:before {
  background-color: fade(@theme-color, 40%) !important;
}
.overlay-theme-colored-5:before {
  background-color: fade(@theme-color, 50%) !important;
}
.overlay-theme-colored-6:before {
  background-color: fade(@theme-color, 60%) !important;
}
.overlay-theme-colored-7:before {
  background-color: fade(@theme-color, 70%) !important;
}
.overlay-theme-colored-8:before {
  background-color: fade(@theme-color, 80%) !important;
}
.overlay-theme-colored-9:before {
  background-color: fade(@theme-color, 90%) !important;
}


/* border-theme-color */
.border-theme-colored {
  border-color: @theme-color !important;
}
.border-theme-color-1px {
  border: 1px solid @theme-color !important;
}
.border-theme-color-2px {
  border: 2px solid @theme-color !important;
}
.border-theme-color-1px {
  border: 3px solid @theme-color !important;
}
.border-theme-color-1px {
  border: 4px solid @theme-color !important;
}
.border-theme-color-1px {
  border: 1px solid @theme-color !important;
}
.border-theme-color-1px {
  border: 1px solid @theme-color !important;
}
.border-theme-color-1px {
  border: 1px solid @theme-color !important;
}
.border-top-theme-color-1px {
  border-top: 1px solid @theme-color !important;
}
.border-top-theme-color-2px {
  border-top: 2px solid @theme-color !important;
}
.border-top-theme-color-3px {
  border-top: 3px solid @theme-color !important;
}
.border-top-theme-color-4px {
  border-top: 4px solid @theme-color !important;
}
.border-top-theme-color-5px {
  border-top: 5px solid @theme-color !important;
}
.border-top-theme-color-6px {
  border-top: 6px solid @theme-color !important;
}
.border-top-theme-color-7px {
  border-top: 7px solid @theme-color !important;
}
.border-top-theme-color-8px {
  border-top: 8px solid @theme-color !important;
}
.border-top-theme-color-9px {
  border-top: 9px solid @theme-color !important;
}
.border-top-theme-color-10px {
  border-top: 10px solid @theme-color !important;
}

/*Theme Colored Red Border-bottom-color*/
.border-bottom-theme-color-1px {
  border-bottom: 1px solid @theme-color !important;
}
.border-bottom-theme-color-2px {
  border-bottom: 2px solid @theme-color !important;
}
.border-bottom-theme-color-3px {
  border-bottom: 3px solid @theme-color !important;
}
.border-bottom-theme-color-4px {
  border-bottom: 4px solid @theme-color !important;
}
.border-bottom-theme-color-5px {
  border-bottom: 5px solid @theme-color !important;
}
.border-bottom-theme-color-6px {
  border-bottom: 6px solid @theme-color !important;
}
.border-bottom-theme-color-7px {
  border-bottom: 7px solid @theme-color !important;
}
.border-bottom-theme-color-8px {
  border-bottom: 8px solid @theme-color !important;
}
.border-bottom-theme-color-9px {
  border-bottom: 9px solid @theme-color !important;
}
.border-bottom-theme-color-10px {
  border-bottom: 10px solid @theme-color !important;
}

/*Theme Colored Red Border-left-color*/
.border-left-theme-color-1px {
  border-left: 1px solid @theme-color !important;
}
.border-left-theme-color-2px {
  border-left: 2px solid @theme-color !important;
}
.border-left-theme-color-3px {
  border-left: 3px solid @theme-color !important;
}
.border-left-theme-color-4px {
  border-left: 4px solid @theme-color !important;
}
.border-left-theme-color-5px {
  border-left: 5px solid @theme-color !important;
}
.border-left-theme-color-6px {
  border-left: 6px solid @theme-color !important;
}
.border-left-theme-color-7px {
  border-left: 7px solid @theme-color !important;
}
.border-left-theme-color-8px {
  border-left: 8px solid @theme-color !important;
}
.border-left-theme-color-9px {
  border-left: 9px solid @theme-color !important;
}
.border-left-theme-color-10px {
  border-left: 10px solid @theme-color !important;
}

/*Theme Colored Red Border-right-color*/
.border-right-theme-color-1px {
  border-right: 1px solid @theme-color !important;
}
.border-right-theme-color-2px {
  border-right: 2px solid @theme-color !important;
}
.border-right-theme-color-3px {
  border-right: 3px solid @theme-color !important;
}
.border-right-theme-color-4px {
  border-right: 4px solid @theme-color !important;
}
.border-right-theme-color-5px {
  border-right: 5px solid @theme-color !important;
}
.border-right-theme-color-6px {
  border-right: 6px solid @theme-color !important;
}
.border-right-theme-color-7px {
  border-right: 7px solid @theme-color !important;
}
.border-right-theme-color-8px {
  border-right: 8px solid @theme-color !important;
}
.border-right-theme-color-9px {
  border-right: 9px solid @theme-color !important;
}
.border-right-theme-color-10px {
  border-right: 10px solid @theme-color !important;
}


.bx-controls .bx-controls-direction > a {    
  border: 1px solid @theme-color;
}
/* ------ border hover theme colored ------- */
.border-hover-theme-colored:hover {
  border: 1px solid @theme-color !important;
}
.project .project-details {
  border-bottom: 3px solid @theme-color;
}

/* ------ box-shadow ------- */
.box-shadow-theme-colored-6px {
    box-shadow: 0 0 0 6px @theme-color;
}


.team-member .member-info {
     background-color: fade(@theme-color, 70%) !important
}

/* background-color-transparent */
.donate-piechart .piechart canvas {
  background-color: fade(@theme-color, 75%) !important;
  border-radius: 50%;
  max-height: 80px;
}
/* Layer-overlay */
.overlay-theme-colored:before {
  background-color: fade(@theme-color, 80%) !important;
}
/* Layeroverlay */
.post .entry-meta.meta-absolute,
.volunteer .overlay,
.bg-theme-colored-transparent-deep {
  background-color: fade(@theme-color, 90%) !important;
}

.icon-box.box-style1.practice-style3:hover .icon-wrapper,
.zeus .tp-bullet::after {
  background-color: @theme-color;
}

.preloader-dot-loading .cssload-loading i {
  background: @theme-color !important;
}

.portfolio-filter a.active, .portfolio-filter a:hover {
  background-color: @theme-color;
  color: @white-base;
}


/*
 * background: theme-color
 * -----------------------------------------------
*/
.tab-slider .nav.nav-pills a:hover,
.tab-slider .nav.nav-pills a.active,
.custom-nav-tabs > li > a:hover,
.widget .tags a:hover,
.progress-item .progress-bar,
.small-title .title::after,
.title-icon::before,
.title-icon::after,
.testimonial .item::after,
.drop-caps.colored-square p:first-child:first-letter,
.drop-caps.colored-rounded p:first-child:first-letter,
.list-icon.theme-colored.square li i,
.list-icon.theme-colored.rounded li i,
.working-process.theme-colored a,
.widget.dark .tags a:hover,
.blog-posts .post .entry-content .post-date.right,
.horizontal-tab-centered .nav-pills > li > a:hover,
.horizontal-tab-centered .nav-pills > li.active > a,
.horizontal-tab-centered .nav-pills > li.active  > a:hover,
.horizontal-tab-centered .nav-pills > li.active > a:focus,
.owl-theme.dot-theme-colored .owl-controls .owl-dot span,
.pagination.theme-colored li.active a,
.section-title .both-side-line::after,
.section-title .both-side-line::before,
.section-title .top-side-line::after,
.section-title .left-side-line::before,
.section-title .right-side-line::before,
.product .tag-sale,
.owl-theme .owl-dots .owl-dot.active span,
.title-icon::after,
.title-icon::before,
.line-bottom-double-line-centered:after,
.line-bottom-double-line-centered:before,
.line-bottom:after,
.line-bottom-no-border:after,
.line-bottom-centered:after,
.line-bottom-centered:before,
.vertical-line::after,
.vertical-line::before,
.title-dots span,
.project .hover-link i,
.project:hover .project-details,
.pricing-ribbon h5,
.bx-controls .bx-controls-direction > a:hover,
.price-tag,
.specification tr:first-child td,
.domain-price-table tr:first-child td,
.vps-plan .title {
  background: @theme-color;
}

.owl-carousel.owl-nav-top.nav-theme-colored .owl-controls .owl-nav .owl-prev, 
.owl-carousel.owl-nav-top.nav-theme-colored .owl-controls .owl-nav .owl-next {
  background: @theme-color;
}

.ui-state-highlight {
  background: @theme-color !important;
  color: @white-base !important;
}

.services-tab .nav-tabs > li.active > a,
.services-tab .nav-tabs > li.active > a:hover,
.services-tab .nav-tabs > li.active > a:focus,
.services-tab .nav-tabs > li a:hover,
.services-tab .nav-tabs > li a:focus {
  background-color: @theme-color !important;
}

/*
 * text: theme-color
 * -----------------------------------------------
*/
ul.list.theme-colored li:before,
ul.list.theme-colored.angle-double-right li:before,
ul.list.theme-colored.angle-right li:before,
ul.list.theme-colored.check-circle li:before,
ul.list.theme-colored.check li:before,
.list-icon.theme-colored li i,
.title-icon [class^="flaticon-"]::after, 
.title-icon [class^="flaticon-"]::before,
.menuzord-menu > li > .megamenu .megamenu-row li:hover > a,
.menuzord-menu > li > .megamenu .megamenu-row .post a:hover,
.menuzord-menu > li > .megamenu .megamenu-row li:hover > a i,
.menuzord-menu > li > .megamenu .megamenu-row .post a:hover i,
.pricing-table .table-list li i,
.testimonial-carousel.boxed .content::after,
.blog-posts .post .entry-meta li i,
.widget .twitter-feed li::after,
.widget .address li i,
.icon-box.box-style1.practice-style3 i,
.attorney-carousel .content .contact-area i,
.attorney-address li i,
.icon-box.services-style1:hover .heading,
.horizontal-contact-widget .widget::before,
.horizontal-contact-widget .each-widget::before,
.small-title i,
.drop-caps.text-colored p:first-child:first-letter,
.schedule-box:hover .schedule-details .title a,
.widget.dark .nav-tabs li.active a,
.pagination.theme-colored li a,
.pager.theme-colored a,
.widget .post-title a:hover,
.volunteer .info .name a,
.donation-form .form-group label,
.title-icon [class^="flaticon-"]::after, 
.title-icon [class^="flaticon-"]::before,
.brochured li a i,
.services-tab .nav-tabs > li > a i {
  color: @theme-color;
}

.brochured li a:hover {
    background: @theme-color;
    color: @white-base;
}
.brochured li a:hover i {
    color: @white-base;
}

.owl-carousel.owl-nav-top .owl-controls .owl-nav .owl-prev i, 
.owl-carousel.owl-nav-top .owl-controls .owl-nav .owl-next i {
    color: @theme-color;
}
.owl-carousel.owl-nav-top .owl-controls .owl-nav .owl-prev, 
.owl-carousel.owl-nav-top .owl-controls .owl-nav .owl-next {
    border: 1px solid @theme-color;
}
.owl-carousel.owl-nav-top .owl-controls .owl-nav > div:hover {
    background-color: @theme-color;
}

.panel-group .panel-title a.active {
  background: @theme-color none repeat scroll 0 0;
  color: @white-base;
}

.star-rating span::before {
  color: @theme-color !important;
}
/* menuzord */
.menuzord .menuzord-menu > li.active > a, 
.menuzord .menuzord-menu > li:hover > a, 
.menuzord .menuzord-menu ul.dropdown li:hover > a {
  background: @theme-color;
  color: @white-base;
}
.menuzord .menuzord-menu > li.active > a i, 
.menuzord .menuzord-menu > li:hover > a i, 
.menuzord .menuzord-menu ul.dropdown li:hover > a i {
  color: @theme-color;
}
.menuzord .menuzord-menu > li.active > a i, 
.menuzord .menuzord-menu > li:hover > a i, 
.menuzord .menuzord-menu ul.dropdown li:hover > a i {
  color: @white-base;
}
/*
 * border: theme-color
 * -----------------------------------------------
*/
.icon-box.icon-rounded-bordered i,
.widget .line-bottom:after,
.widget .line-bottom:after,
.pager.theme-colored a {
  border: 1px solid @theme-color;
}
.border-theme-colored,
.owl-theme .owl-dots .owl-dot span {
  border-color: @theme-color;
}
.icon-box.box-style1.practice-style3:hover .icon-wrapper::after {
  border-color: @theme-color transparent transparent;
}
.opening-hours ul li {
  border-bottom: 1px dashed @theme-color;
}

.project .project-details {
  border-bottom: 3px solid @theme-color;
}

/* border-left-color */
blockquote.theme-colored {
    border-left: 3px solid @theme-color;
}
blockquote.bg-theme-colored {
    border-left: 3px solid darken(@theme-color, 10%);
    color: @white-base;

    footer {
      color: @gray-lighter;
    }
}
blockquote.gray.bg-theme-colored {
    background-color: @gray-lighter !important;
    border-left: 3px solid @theme-color;
    color: @theme-color;
    
    footer {
      color: @gray-light;
    }
}
.attorney-address li {
    border-left: 1px solid @theme-color;
}
.section-title.title-border {
    border-left: 4px solid @theme-color;
}

/* border-top-color */
.member-info {
  border-top: 3px outset @theme-color;
}
.cssload-tri {
  border-top: 27px solid @theme-color;
}

/* border-right-color */
.border-right-red {
  border-right: 1px solid @theme-color;
}
.section-title.title-border.title-right {
    border-right: 4px solid @theme-color;
}

/* border-bottom-color */
// .border-bottom {
//   border-bottom: 1px solid @theme-color;
// }

.services-list li.active a {
  background-color: @theme-color !important;
}
.services-list ul li a:hover {    
    background-color: @theme-color;
    color: @white-base;
}
.services-list li.active a {
    border: 1px solid @theme-color;
}
.cssload-tri.cssload-invert {
  border-bottom: 27px solid @theme-color;
}
/* box-shadow */

/*
 * btn-default: theme-color
 * -----------------------------------------------
*/
.btn-default.btn-theme-colored {
  .button-variant(@theme-color; @btn-default-bg; @theme-color);
}
.btn-default.btn-theme-colored:hover,
.btn-default.btn-theme-colored:active,
.btn-default.btn-theme-colored:focus {
  background-color: @theme-color;
  border-color: @theme-color;
  color: @white-base;
}


/*
 * btn-border
 * -----------------------------------------------
*/
.btn-border.btn-theme-colored {
  .button-variant(@theme-color; @btn-border-bg; @theme-color);
}
.btn-border.btn-theme-colored:hover,
.btn-border.btn-theme-colored:active,
.btn-border.btn-theme-colored:focus {
  background-color: darken(@theme-color, 10%);
  border-color: darken(@theme-color, 10%);
  color: @white-base;
}


/*
 * btn-dark
 * -----------------------------------------------
*/
.btn-dark.btn-theme-colored {
  .button-variant(@btn-dark-color; @theme-color; @theme-color);
}


/*
 * btn-gray
 * -----------------------------------------------
*/
.btn-gray.btn-theme-colored {
  .button-variant(@white-base; lighten(@theme-color, 10%); lighten(@theme-color, 10%));
}

/*
 * btn-primary: theme-color
 * -----------------------------------------------
*/
.btn-theme-colored {
  .button-variant(@btn-primary-color; @theme-color; @theme-color)
}

/*
 * btn-transparent
 * -----------------------------------------------
*/
.btn-transparent {
  background-color: transparent;
  color: @white-base;
}
.btn-transparent.btn-theme-colored {
  background-color: transparent;
}
.btn-transparent.btn-dark {
  background-color: @black-111;
  border-color: @black-111;

  &:hover {
    background-color: darken(@black-111, 10%);
    border-color: darken(@black-111, 10%);
  }
}
.btn-transparent.btn-dark.btn-theme-colored {
  background-color: @theme-color;
  border-color: @theme-color;

  &:hover {
    background-color: darken(@theme-color, 10%);
    border-color: darken(@theme-color, 10%);
  }
}
.btn-transparent.btn-border {
  background-color: transparent;
  border-color: @gray-lighter;

  &:hover {
    background-color: @gray-lighter;
    color: @black;
  }
}
.btn-transparent.btn-border.btn-theme-colored {
  background-color: transparent;
  border-color: @theme-color;

  &:hover {
    background-color: @theme-color;
    color: @white-base;
  }
}

.btn-gray.btn-transparent {
  background-color: @gray-lightgray;
  color: @black-333;

  &:hover {
    background-color: lighten(@gray-lightgray, 10%);
    color: darken(@black-333, 10%);
  }
}

.btn-gray.btn-transparent.btn-theme-colored {
  background-color: lighten(@theme-color, 10%);
  color: @white-base;

  &:hover {
    background-color: darken(@theme-color, 10%);
    color: darken(@white-base, 10%);
  }
}

.btn-hover-theme-colored {
  &:hover {
    background-color: @theme-color;
    border-color: @theme-color;
    color: @white-base;
  }
}

/*
 * Shortcode: social-icons Theme Colored
 * -----------------------------------------------
*/
.icon-theme-colored {
  a {
    color: @theme-color;
  }

  &.icon-bordered {
    a {
      border-color: @theme-color;

      &:hover {
        background-color: @theme-color;
        color: @white-base;
      }
    }
  }

  &.icon-dark {
    a {
      background-color: @theme-color;
      color: @white-base;

      &:hover {
        background-color: darken(@theme-color, 10%);
        color: darken(@white-base, 10%);
      }
    }

    &.icon-bordered {
      a {
        border-color: @theme-color;
        color: @theme-color;

        &:hover {
          background-color: @theme-color;
          border-color: @theme-color;
          color: @white-base;
        }
      }
    }
  }

  &.icon-gray {
    a {
      color: @theme-color;

      &:hover {
        color: darken(@theme-color, 10%);
      }
    }

    &.icon-bordered {
      a {
        color: @theme-color;

        &:hover {
          background-color: @gray-lighter;
          border-color: @gray-lighter;
          color: @theme-color;
        }
      }
    }
  }
}

.icon-theme-colored {
  a {
    &:hover {
      color: darken(@theme-color, 10%);
    }
  }  
}

.icon-hover-theme-colored {
  a {
    &:hover {
      background-color: @theme-color;
      border-color: @theme-color;
      color: @white-base;
    }
  }  
}

.social-icons.icon-hover-theme-colored {
  a {
    &:hover {
      background-color: @theme-color;
      border-color: @theme-color;
      color: @white-base;
    }
  }  
}

/*
 * Shortcode: Icon Boxes Theme Colored
 * -----------------------------------------------
*/
.icon-box{
  &.iconbox-theme-colored {
    .icon {
      color: @theme-color;

      &.icon-border-effect {
        &::after {
          box-shadow: 0 0 0 3px @theme-color;
        }
      }

      &.icon-bordered {
        border-color: @theme-color;

        &:hover {
          background-color: @theme-color;
          color: @white-base;
        }
      }

      &.icon-gray {
        &:hover {
          background-color: @theme-color;
          color: @white-base;
        }

        &.icon-bordered {
          border-color: @gray-lighter;

          &:hover {
            background-color: @theme-color;
            border-color: @theme-color;
            color: @white-base;
          }
        }
      }

      &.icon-dark {
        background-color: @theme-color;
        color: @white-base;

        &:hover {
          color: @white-base;
        }

        &.icon-bordered {
          background-color: transparent;
          border-color: @theme-color;
          color: @theme-color;

          &:hover {
            background-color: @theme-color;
            border-color: @theme-color;
            color: @white-base;
          }
        }
      }



      &.icon-white {
        background-color: @white-base;
        color: @theme-color;

        &:hover {
          background-color: @theme-color;
          color: @white-base;
        }

        &.icon-bordered {
          background-color: transparent;
          border-color: @theme-color;
          color: @white-base;

          &:hover {
            background-color: @theme-color;
            border-color: @theme-color;
            color: @white-base;
          }
        }
      }






    }

    &.iconbox-border {
      border-color: @theme-color;
    }
  }
}